import React from "react";
import styles from "./LetsTalk.module.css";
import { Fade } from "react-awesome-reveal";

const LetsTalk = () => {
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<Fade triggerOnce direction="down">
					<div className={styles.left}>
						<h6>LET'S TALK</h6>
						<h2>Get in Touch.</h2>
						<p>Having troubles, suggestions or informations you will like to share? Drop a message.</p>
						<div className={styles.contactInfos}>
							<div>
								<div className={styles.iconWrapper}>
									<img src="/home2/icons/location.svg" alt="location icon" />
								</div>
								<div>
									<h4>Head Office</h4>
									<p>55 Roman Way Hanham, Bristol, United Kingdom.</p>
								</div>
							</div>
							<div>
								<div className={styles.iconWrapper}>
									<img src="/home2/icons/envelop.svg" alt="envelop icon" />
								</div>
								<div>
									<h4>Email Us</h4>
									<p>support@quantumfinances.co.uk</p>
								</div>
							</div>
						</div>
					</div>
				</Fade>
				<Fade triggerOnce direction="up">
					<div className={styles.right}>
						<div className={styles.imageContainer}>
							<img src="/home2/stockImages/contactus.jpg" alt="company meeting" />
						</div>
					</div>
				</Fade>
			</div>
		</section>
	);
};

export default LetsTalk;
